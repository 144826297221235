<script lang="ts">
export { type Action } from '/~/components/third-party-user/third-party-user-permissions-list-item.vue'
</script>

<script setup lang="ts">
import { computed } from 'vue'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import UserListItem, {
  Action,
} from '/~/components/third-party-user/third-party-user-permissions-list-item.vue'
import { useLocalization } from '/~/composables/localization'
import { type ThirdPartyUser } from '/~/composables/third-party-users/'

const { translate } = useLocalization()

interface ActionEventPayload {
  action: Action
  user: ThirdPartyUser
}

type ActionHandler = (user: ThirdPartyUser) => void

interface Props {
  loading?: boolean
  users?: Array<ThirdPartyUser>
  onAdd?: () => void
  onBack?: () => void
  onEdit?: ActionHandler
  onInvite?: ActionHandler
  onReactivate?: ActionHandler
  onDeactivate?: ActionHandler
  onDelete?: ActionHandler
  providerTitle: string
}

const props = withDefaults(defineProps<Props>(), {
  loading: false,
  users: () => [],
})

const handleAction = (payload: ActionEventPayload) => {
  switch (payload.action) {
    case 'edit':
      props.onEdit?.(payload.user)
      break
    case 'invite':
      props.onInvite?.(payload.user)
      break
    case 'reactivate':
      props.onReactivate?.(payload.user)
      break
    case 'deactivate':
      props.onDeactivate?.(payload.user)
      break
    case 'delete':
      props.onDelete?.(payload.user)
      break

    default:
      props.onEdit?.(payload.user)
      break
  }
}

const onAddHandler = computed(() => {
  if (props.loading || props.users.length < 1 || !props.onAdd) return undefined

  return props.onAdd
})
</script>

<template>
  <base-aside-page
    ref="baseAsidePage"
    :title="`${translate('tpu.authorised')} User permissions`"
    :action="onAddHandler"
    :back="onBack"
  >
    <base-loader v-if="loading" fullwidth />

    <!-- NO Users template -->
    <div
      v-else-if="users.length < 1"
      class="flex flex-col items-center rounded border bg-white px-6 pb-8 pt-8"
    >
      <base-icon
        svg="heroicons/solid/question-mark-circle"
        :size="64"
        class="rounded-full bg-primary p-4 text-white"
      />

      <h4 class="mb-3 mt-7 pt-0.5 text-xl font-bold">
        No {{ translate('tpu.authorised') }} Users Found
      </h4>

      <p>
        Here, you can easily add an
        {{ String(translate('tpu.authorised')).toLowerCase() }} user to your
        {{ providerTitle }} account to streamline collaboration with trusted
        parties such as your bookkeeper, or accountant.
      </p>

      <br />

      <p>
        Begin by clicking the “Add new user” button to invite a new authorised
        user.
      </p>

      <br />

      <base-button
        size="xl"
        :rounded="false"
        class="mt-2 rounded-sm"
        @click="onAdd"
      >
        <span class="w-32 text-base font-normal">Add new user</span>
      </base-button>
    </div>

    <!-- Users list -->
    <div v-else class="space-y-6">
      <p>
        Empower your team by granting full platform access to trusted
        individuals such as your accountant or business partner.
      </p>
      <p>
        Once you've added an authorised user, they'll receive an email
        invitation to join the platform. You have complete control
        {{ translate('common.and') }} can easily deactivate their access
        whenever necessary.
      </p>

      <div class="space-y-3 py-2">
        <user-list-item
          v-for="user in users"
          :key="user.externalId"
          :user="user"
          @action="handleAction"
        />
      </div>
    </div>
  </base-aside-page>
</template>
