<script setup lang="ts">
import Vue from 'vue'
import { useRouter } from 'vue-router/composables'
import bottomSheet from '/~/core/bottom-sheet'
import ThirdPartyUserPermissionsPure from '/~/components/third-party-user/third-party-user-permissions.pure.vue'
import { useLocalization } from '/~/composables/localization'
import { useProvider } from '/~/composables/provider'
import {
  ThirdPartyUser,
  useThirdPartyUsers,
} from '/~/composables/third-party-users'

const router = useRouter()
const {
  isLoading,
  thirdPartyUsers,
  fetchThirdPartyUsers,
  reinviteThirdPartyUser,
  deactivateUser,
  reactivateUser,
  deleteUser,
} = useThirdPartyUsers()
const { generalEmail, providerTitle } = useProvider()
const { translate } = useLocalization()

fetchThirdPartyUsers()

const onAdd = async () => {
  router.push({ hash: '#profile-third-party-user-add' })
}

const onEdit = async (user: ThirdPartyUser) => {
  router.push({
    hash: '#profile-third-party-user-add',
    params: { user },
  })
}

const onInvite = async (user: ThirdPartyUser) => {
  bottomSheet.show('confirm', {
    to: 'menu-modal',
    props: {
      title: 'Resend invite',
      description: `
        <div class="space-y-4">
          <p class="text-left">
            Please double-check the email address you provided earlier. If it's correct, click "Resend" to ensure prompt delivery. If the email is incorrect, please "Delete" this invite and create a new invite with the correct email address.
          </p>

          <p class="text-left">
            Recipient's Email Address: <br /> ${user.email}
          </p>

          <p class="text-left">
            If you need assistance, contact our support team at ${generalEmail.value}. We're here to ensure your invitees can access our platform seamlessly.
          </p>
        </div>
      `,
      confirmLabel: 'Resend',
      cancelLabel: 'Edit details',
      onConfirm: async () => {
        try {
          await reinviteThirdPartyUser(user.externalId)
          Vue.notify({
            type: 'success',
            text: 'Invite sent successfully.',
          })
        } catch (error: any) {
          console.error(error)
          Vue.notify({
            type: 'error',
            text: 'Oops! Something went wrong. Please try again later.',
          })
        }
      },
      onCancel: () => {
        router.push({
          hash: '#profile-third-party-user-add',
          params: { user },
        })
      },
    },
  })
}

const onReactivate = async (user: ThirdPartyUser) => {
  bottomSheet.show('confirm', {
    to: 'menu-modal',
    props: {
      title: `Reactivate ${translate('tpu.authorised')} User`,
      description: `
        <div class="space-y-4">
          <p class="text-left">
            Are you sure you want to activate the access of this ${String(
              translate('tpu.authorised')
            ).toLowerCase()} user? This action will grant them permissions and they will be able to access the platform.
          </p>
        </div>
      `,
      confirmLabel: 'Confirm',
      cancelLabel: 'Cancel',
      onConfirm: async () => {
        try {
          await reactivateUser(user)
          Vue.notify({
            type: 'success',
            text: `${translate(
              'tpu.authorised'
            )} User successfully reactivated.`,
          })
          fetchThirdPartyUsers()
        } catch (error: any) {
          console.error(error)
          Vue.notify({
            type: 'error',
            text: 'Oops! Something went wrong. Please try again later.',
          })
        }
      },
    },
  })
}

const onDeactivate = async (user: ThirdPartyUser) => {
  bottomSheet.show('confirm', {
    to: 'menu-modal',
    props: {
      title: `Deactivate ${translate('tpu.authorised')} User`,
      description: `
        <div class="space-y-4">
          <p class="text-left">
            Are you sure you want to deactivate the access of this ${String(
              translate('tpu.authorised')
            ).toLowerCase()} user? This action will revoke their permissions and they will no longer be able to access the platform.
          </p>
        </div>
      `,
      confirmLabel: 'Confirm',
      cancelLabel: 'Cancel',
      onConfirm: async () => {
        try {
          await deactivateUser(user)
          Vue.notify({
            type: 'success',
            text: `${translate(
              'tpu.authorised'
            )} User successfully deactivated.`,
          })
          fetchThirdPartyUsers()
        } catch (error: any) {
          console.error(error)
          Vue.notify({
            type: 'error',
            text: 'Oops! Something went wrong. Please try again later.',
          })
        }
      },
    },
  })
}

const onDelete = async (user: ThirdPartyUser) => {
  bottomSheet.show('confirm', {
    to: 'menu-modal',
    props: {
      title: `Delete ${translate('tpu.authorised')} User`,
      description: `
        <div class="space-y-4">
          <p class="text-left">
            Are you sure you want to delete this ${String(
              translate('tpu.authorised')
            ).toLowerCase()} user? This action is irreversible and will permanently remove their account and all associated data from the platform.
          </p>
        </div>
      `,
      confirmLabel: 'Confirm',
      cancelLabel: 'Cancel',
      onConfirm: async () => {
        try {
          await deleteUser(user.externalId)
          Vue.notify({
            type: 'success',
            text: `${translate('tpu.authorised')} User successfully deleted.`,
          })
          fetchThirdPartyUsers()
        } catch (error: any) {
          console.error(error)
          Vue.notify({
            type: 'error',
            text: 'Oops! Something went wrong. Please try again later.',
          })
        }
      },
    },
  })
}
</script>

<template>
  <third-party-user-permissions-pure
    :loading="isLoading"
    :users="thirdPartyUsers"
    :provider-title="providerTitle"
    :on-add="onAdd"
    :on-edit="onEdit"
    :on-invite="onInvite"
    :on-reactivate="onReactivate"
    :on-deactivate="onDeactivate"
    :on-delete="onDelete"
  />
</template>
